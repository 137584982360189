<template>
  <v-dialog max-width="500" persistent v-model="open">
    <v-card>
      <v-card-title class="text-h5 bg-prosk-secondary white--text">{{ heading }}</v-card-title>
      <v-card-text class="mt-3 font-weight-bold">{{message}}</v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('onClose')" outlined color="red" small>
          {{$t('no')}}
        </v-btn>
        <v-btn @click="$emit('onConfirm')" small :loading="loading" color="#59D79D" class="white--text">
          {{$t('yes')}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['heading', 'message', 'onClose', 'onConfirm', 'open', 'loading']
};
</script>
