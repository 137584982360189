<template>
  <v-main class="container-prosker" v-if="currentUser.type_user === $options.USER_TYPE_ADMIN">
    <delete-warning
        :confirm-delete="confirmDelete"
        :on-close="onDeleteClose"
        :show-delete="showDelete"
        :loading="loading"
    />
    <confirmation-dialog
        :heading="$t('modifyStatus')"
        :open="showConfirmationDialog"
        :loading="loadingConfirmation"
        @onClose="closeConfirmationDialog"
        @onConfirm="updateStatus"
        :message="$t('sureWantToUpdateStatus')"/>
    <v-data-table
        :options.sync="pagination"
        :headers="headers"
        :loading="loadingTable"
        :items="jobsList"
        :footer-props="{itemsPerPageText: $t('rowsPerPage')}"
        :search="search"
        :server-items-length="pagination.totalItems"
        class="elevation-1 mt-5"
        item-key="id"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>{{ $t('jobsAdmin') }}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-text-field :label="$t('search')" append-icon="search" hide-details
                        single-line v-model="search" color="#59D79D"></v-text-field>
            <div class="d-none">
                <jobs-dialog
                  :item="editedItem"
                  :on-close="onAddClose"
                  :new-item="newItem"
                  :show-add="showAdd"
                  :is-admin="true"
                  :on-added="onAddCallback"
                />
            </div>
        </v-toolbar>
      </template>
      <template v-slot:no-data>
        <v-alert :value="true" color="error" icon="warning" class="white--text">
          <span class="white--text">
            {{ $t("noData") }}
          </span>
        </v-alert>
      </template>
      <template v-slot:item.title="{ item }">
        <span class="caption">{{ item.title }}</span>
      </template>
      <template v-slot:item.start_date="{ item }">
        <span class="caption">{{ getDateFormat(item.start_date) }}</span>
      </template>
      <template v-slot:item.end_date="{ item }">
        <span class="caption">{{ getDateFormat(item.end_date) }}</span>
      </template>
      <template v-slot:item.status="{ item }">
        <v-select
            :items="items"
            :label="$t('modifyStatus')"
            dense
            disabled
            item-text="name"
            item-value="status"
            menu-props="auto"
            hide-details
            color="#59D79D"
            class="mt-3"
            @change="openConfirmationDialog(item.id, item.status)"
            v-model="item.status"
        >
          <template v-slot:item="data">
            <v-list-item-content>
              <v-list-item-subtitle>
                <b>{{ $t(data.item.name) }}</b>
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
          <template #selection="{ item }">
            <v-chip :color="getColorClass(item.status)" small class="white--text">{{ $t(item.name) }}</v-chip>
          </template>
        </v-select>
      </template>
      <template v-slot:item.action="{ item }">
        <tooltip-button
          :icon="'mdi-eye'"
          :item="item"
          :color="'secondary'"
          :action="openDetail"
          :message="'detail'"
        ></tooltip-button>
        <tooltip-button
            :icon="'mdi-delete'"
            :action="openWarning"
            :item="item.id"
            :color="'red'"
            :message="'delete'"
        ></tooltip-button>
      </template>
    </v-data-table>
  </v-main>
</template>
<script>
import axios from 'axios';
import moment from 'moment/moment';
import { mapGetters } from 'vuex';
import TooltipButton from '@/components/socialvue/buttons/TooltipButton.vue';
import errorDialog from '@/components/socialvue/mixins/errorDialog';
import { USER_TYPE_ADMIN } from '@/misc/constants';
import DeleteWarning from '@/components/socialvue/dialogs/DeleteWarning';
import ConfirmationDialog from '@/components/socialvue/dialogs/ConfirmationDialog.vue';
import _ from 'lodash';
import JobsDialog from '@/components/socialvue/dialogs/JobsDialog.vue';

export default {
  name: 'JobsAdmin',
  components: { JobsDialog, ConfirmationDialog, TooltipButton, DeleteWarning },
  mixins: [errorDialog],
  data () {
    return {
      search: '',
      showAdd: false,
      editedItem: {},
      items: [
          {
            status: 10,
            name: 'new'
          },
          {
            status: 20,
            name: 'rejected'
          },
          {
            status: 30,
            name: 'readyToExecute'
          },
          {
            status: 40,
            name: 'inProgress'
          },
          {
            status: 60,
            name: 'clientCancel'
          },
          {
            status: 70,
            name: 'proskerComplete'
          },
          {
            status: 80,
            name: 'clientRejected'
          },
          {
            status: 90,
            name: 'complete'
          },
          {
            status: 100,
            name: 'proskerHelpRequest'
          },
          {
            status: 110,
            name: 'clientHelpRequest'
          }
      ],
      id: 0,
      pagination: { totalItems: 0, per_page: 10, page: 1, sortBy: ['id'] },
      isDirty: false,
      status: null,
      itemToDelete: null,
      showDelete: false,
      showConfirmationDialog: false,
      jobsList: [],
      loading: false,
      loadingTable: false,
      loadingConfirmation: false
    };
  },
  created () {
    this.list();
    this.setImportedConstants();
  },
  computed: {
    headers () {
        return [
            { text: this.$t('title'), value: 'title' },
            { text: this.$t('client'), value: 'client_user.username' },
            { text: this.$t('Prosker'), value: 'prosker_user.username' },
            { text: this.$t('startDate'), value: 'start_date' },
            { text: this.$t('endDate'), value: 'end_date' },
            { text: this.$t('status'), value: 'status', width: '20%' },
            { text: this.$t('actions'), value: 'action', sortable: false }
        ];
    },
    ...mapGetters({
      currentUser: 'auth/currentUser'
    })
  },
  watch: {
    'search': {
      handler () {
        this.resetPaginationForSearch(this);
      },
      deep: true
    },
    'pagination': {
      handler () {
        if (!this.isDirty) {
          this.list();
        }
      },
      deep: true
    }
  },
  methods: {
    newItem () {
        this.reset();
        this.showAdd = true;
        this.editedItem.isEdit = false;
        this.editedItem.isClient = true;
    },
    onAddCallback () {
        this.list();
    },
    reset () {
        this.editedItem = {};
        this.editedItem.client = '';
        this.editedItem.review = '';
        this.editedItem.end_date = '';
        this.editedItem.start_date = '';
        this.editedItem.price = '';
        this.editedItem.end_date_format = '';
        this.editedItem.start_date_format = '';
        this.editedItem.description = '';
        this.editedItem.title = '';
        this.editedItem.payment_required = false;
    },
    onAddClose () {
        this.reset();
        this.editedItem = {};
        this.showAdd = false;
        this.editedItem.isEdit = false;
        this.editedItem.isClient = false;
    },
    openConfirmationDialog (id, status) {
      this.showConfirmationDialog = true;
      this.id = id;
      this.status = status;
    },
    openDetail (item) {
      this.$router.push({ name: 'social.jobs-admin-details', params: { query: item } })
    },
    closeConfirmationDialog () {
      this.showConfirmationDialog = false;
      this.id = null;
      this.status = null;
      this.list();
    },
    openWarning (item) {
      this.showDelete = true;
      this.itemToDelete = item;
    },
    onDeleteClose () {
      this.showDelete = false;
    },
    confirmDelete () {
      this.loading = true;
      const url = `${process.env.VUE_APP_BASE_URL}/api/jobs/${this.itemToDelete}`;
      axios.delete(url).then(() => {
        this.list();
        this.loading = false;
      }).catch((error) => {
        this.loading = false;
        this.showError(error);
      });
      this.showDelete = false;
    },
    getColorClass (status) {
      if (status === 10) {
        return 'blue lighten-2';
      } else if (status === 20) {
        return 'red lighten-1';
      } else if (status === 30) {
        return '#7EBE72 darken-1';
      } else if (status === 40) {
        return 'cyan darken-3';
      } else if (status === 60) {
        return 'red darken-2';
      } else if (status === 70) {
        return 'green darken-1';
      } else if (status === 80) {
        return 'red darken-4';
      } else if (status === 90) {
        return 'green darken-2';
      } else if (status === 100) {
        return 'yellow darken-2';
      } else if (status === 110) {
        return 'blue darken-2';
      }
    },
    updateStatus () {
      this.loadingConfirmation = true;
      const url = `${process.env.VUE_APP_BASE_URL}/api/jobs/${this.id}`;
      const data = {
        status: this.status
      }
      axios.patch(url, data).then(() => {
        this.loadingConfirmation = false;
        this.list();
        this.showConfirmationDialog = false;
      }).catch((error) => {
        this.loadingConfirmation = false;
        this.showError(error);
      });
    },
    setImportedConstants () {
      this.$options.USER_TYPE_ADMIN = USER_TYPE_ADMIN;
    },
    getDateFormat (date) {
      const originalDate = new Date(date);
      return moment(moment.utc(originalDate)).format('DD-MM-YYYY');
    },
    resetPagination () {
      if (!this.isDirty) {
        this.pagination = { totalItems: 0, per_page: 10, page: 1, sortBy: ['id'] };
      }
    },
    resetPaginationForSearch: _.debounce((self) => {
      if (!self.isDirty) {
        self.resetPagination();
      }
    }, 500),
    async list () {
      this.isDirty = true;
      this.loadingTable = true;
      const url = `${process.env.VUE_APP_BASE_URL}/api/jobs?search=${this.search}&per_page=${this.pagination.per_page}
      &page=${this.pagination.page}&sortBy=${this.pagination.sortBy[0]}`;
      await axios.get(url).then(({ data }) => {
        this.isDirty = false;
        this.jobsList = data.data;
        this.pagination.totalItems = data.total;
        this.loadingTable = false;
      }).catch((error) => {
        this.isDirty = false;
        this.loadingTable = false;
        this.showError(error);
      });
    }
  }
};
</script>
